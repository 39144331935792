/* You can add global styles to this file, and also import other style files */
a { cursor: pointer }

* {
  box-sizing: border-box;
}

body {
  padding: 80px 0 0 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-image: url("./assets/img/bg-01.webp");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: -webkit-fill-available;
  min-height: 100vh;
  overflow-x: hidden;

  @media screen and (min-width: 1200px) {
    padding: 0 0 0 400px;
  }

  &.logged-in {
    padding: 60px 20px 20px 20px;

    @media screen and (min-width: 1200px) {
      padding: 20px 20px 20px 420px;
    }
  }
}

h1, h2, h3, h4 {
  font-weight: 400;
  font-family: 'Black Ops One', cursive;
}

.login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;

  .container {
    width: 450px;
    max-width: calc(100% - 64px);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    padding: 4rem;
    position: relative;

    @media screen and (max-width: 600px) {
      padding: 40px 20px;
    }

    &:before {
      content: '';
      position: absolute;
      background-color: rgba(255, 255, 255, 0.15);
      inset: 0;
      transform: rotate(-5deg);
      z-index: -1;
      border-radius: 10px;
    }

    img {
      max-width: 100%;
      margin: 0 auto 0 auto;
      max-height: 200px;
      display: block;
    }

    h1 {
      text-transform: uppercase;
      text-align: center;
      margin-top: -40px;
      letter-spacing: 0.2rem;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;

    .invalid-feedback {
      margin-top: 10px;
      color: #dc3545;
    }

    img {
      width: 20px;
      position: absolute;
      z-index: 2;
      top: 10px;
      left: 12px;

      &.pass {
        left: 13px;
      }
    }

    label {
      font-size: 18px;
      text-align: center;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    input {
      padding: 10px 10px 10px 40px;
      border: none;
      border-radius: 100px;
      width: 100%;
      transition: background .5s;
      font-size: 18px;
      text-align: left;
      //box-shadow: 0px 5px 10px;
      outline: none;
      background-color: rgba(255, 255, 255, 0.5);

      &:focus {
        background: white;
      }
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 10px;

    a {
      color: rgba(0,0,0,0.8);
      font-size: 14px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .create {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;

    a {
      color: #000000;
    }
  }

  button {
    padding: 15px 15px;
    border: none;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: rgba(69, 92, 201, 0.6);
    color: #ffffff;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    width: 100%;
    transition: background-color .5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgba(69, 92, 201, 1);
    }

    img {
      height: 20px;
    }
  }

  .alert {
    text-align: center;
    border-top: 2px solid red;
    margin-top: 20px;
    padding: 10px;
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 0 16px;
  z-index: 1000;

  @media screen and (min-width: 1200px) {
    display: none;
  }


  img {
    height: 40px;
  }

  svg {
    cursor: pointer;
  }

  a {
    color: #ffffff;
    font-weight: 600;
  }
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1050;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  overflow-y: auto;

  &.active {
    display: flex;
  }

  img.logo {
    display: none;
    max-width: 100%;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 400px;
    height: 100vh;
    background-color: rgba( 255, 255, 255, 0.4);

    img.logo {
      display: block;
      width: 300px;
      margin-bottom: 20px;
    }

    #close {
      display: none;
    }
  }

  #close {
    position: fixed;
    top: 32px;
    right: 32px;
    cursor: pointer;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 100%;

    a {
      color: #000000;
      font-weight: 400;
      font-size: 20px;
      text-transform: uppercase;
      text-decoration: none;
      border-bottom: 1px solid rgba(0,0,0,0.4);
      padding: 20px 10px;
      display: grid;
      grid-template-columns: 40px minmax(0, 1fr);
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
      }

      &:hover, &.active {
        font-weight: 600;
      }
    }
  }
}

.row {
  margin: 20px 0;
  display: grid;
  grid-template-columns: max-content max-content 40px;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0 20px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 10px 0;
  }

  input {
    padding: 8px;
    border: 1px solid #000;
    border-radius: 5px;
    font-size: 16px;
    text-align: left;
    box-shadow: 0px 5px 10px;
    width: 100%;
    max-width: 300px;
  }

  .removeItem {
    border: none;
    background-color: transparent;
    color: red;
    padding: 8px;
    font-size: 20px;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      width: 40px;
    }
  }
}

.table {
  padding-top: 20px;

  h3 {
    font-size: 20px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    color: #ffffff;
    text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
  }

  table {
    width: calc(100% - 40px);
    margin: 20px;

    thead {
      background-color: #0099ca;
      color: #ffffff;
      font-weight: 600;

      th {
        padding: 5px;
      }

      th:first-of-type {
        text-align: left;
      }
    }

    tr {
      &:nth-child(even) td {
        background-color: rgba(255,255,255, 0.4)
      }

      td {
        padding: 5px;

        &:nth-child(2) {
          text-align: center;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &:nth-child(4) {
          text-align: center;
        }
      }
    }
  }

  &.dsab {

    .inner {
      max-width: calc(100vw - 40px);
      overflow-x: auto;
      position: relative;
    }

    @media screen and (min-width: 1200px) {
      grid-column: span 2;
    }

    th {
      text-align: center !important;
      &:nth-child(2) {
        text-align: left !important;
      }
    }

    tr.current td {
      background-color: #27AE60 !important;
    }

    td {
      text-align: center;
      &:nth-child(2) {
        text-align: left;
      }
    }
  }

  .timeChooser {
    display: grid;
    grid-template-columns: minmax(20px, 30px) 1fr minmax(20px, 30px) 1fr;
    padding: 0 25px;
    grid-gap: 10px;
    align-items: center;

    input {
      padding: 5px;
      border: 0;
      border-radius: 5px;
    }
  }
}

.noData {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 40px;
  max-width: 900px;
  margin: 0 auto 80px auto;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  .heading {
    grid-column: span 2;

    @media screen and (max-width: 800px) {
      grid-column: unset;
    }

    h3 {
      font-size: 40px;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      color: #ffffff;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }
  }

  .table {
    background-color: rgba(255,255,255, 0.6);
    border-radius: 5px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-color: rgba(255, 255, 255, 0.15);
      inset: 0;
      transform: rotate(-3deg);
      z-index: -1;
      border-radius: 5px;
    }

    h3 {
      text-align: left;
      padding: 0 25px;
      color: #ffffff;
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
    }

    &.dsab {
      @media screen and (min-width: 800px) {
        grid-column: span 2;
      }
    }
  }

  .sessions {
    grid-column: span 2;

    @media screen and (max-width: 800px) {
      grid-column: unset;
    }

    h3 {
      font-size: 40px;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      color: #ffffff;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }

    .loadMore {
      text-align: center;

      button {
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 8px 10px;
        background-color: #0099ca;
        max-width: 200px;
        text-align: center;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 40%);

        img {
          height: 20px;
        }

        @media screen and (max-width: 800px) {
          font-size: 14px;
        }
      }
    }

    .session {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      background-color: rgba(255,255,255, 0.6);
      margin: 40px 0;
      border-radius: 5px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.15);
        inset: 0;
        transform: rotate(-3deg);
        z-index: -1;
        border-radius: 5px;
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
      }

      .table {
        background-color: transparent;
        &:before {
          content: unset;
        }
      }

      .description {
        padding: 40px;
        display: grid;
        grid-gap: 20px 10px;
        grid-auto-rows: minmax(min-content, max-content);
        grid-template-columns: minmax(min-content, max-content);

        @media screen and (max-width: 800px) {
          padding: 40px 40px 0 40px;
          grid-template-columns: 1fr;
        }

        h3 {
          grid-column: span 2;
          font-size: 20px;
          color: #ffffff;
          text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
          text-align: left;
          margin: 0;
        }

        .links {
          grid-column: span 2;
          display: grid;
          grid-template-columns: max-content max-content;
          grid-gap: 10px;

          @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
          }
        }

        a {
          color: #ffffff;
          font-weight: 600;
          font-size: 16px;
          text-transform: uppercase;
          text-decoration: none;
          padding: 8px 10px;
          background-color: #28a745;
          max-width: 200px;
          text-align: center;
          border-radius: 5px;
          box-shadow: 0px 2px 2px rgb(0 0 0 / 40%);

          @media screen and (max-width: 800px) {
            font-size: 14px;
          }

          &.delete {
            background-color: #dc3545;
          }
        }
      }
    }
  }
}

.head {
  max-width: 780px;
  margin: 0 auto;

  h1 {
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    font-size: 35px;
    text-shadow: #000 0px 0px 1px,   #000 0px 0px 1px,   #000 0px 0px 1px,
    #000 0px 0px 1px,   #000 0px 0px 1px,   #000 0px 0px 1px;

    @media screen and (max-width: 800px) {
      font-size: 20px;
    }
  }
}
